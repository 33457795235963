.language-switcher {
    display: flex;
    gap: 15px;
}

.flag-button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
}

.flag-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
}

.flag-icon:hover {
    transform: scale(1.1);
}
