.cover {
    display: flex;
    justify-content: center;
    position: relative;
}

.language-switcher-container {
    position: absolute;
    top: 20px;
    right: 150px;
    z-index: 10;
}

.add-portal-form {
    max-width: 1200px;
    width: 93%;
    padding-top: 35px; 
}

.center {
    display: flex;
    justify-content: center;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.mb-4 {
    margin-bottom: 2rem !important;
}

@media (max-width: 768px) {
    .cover {
        flex-direction: column;
    }
}
